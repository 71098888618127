import { buildCollection, buildProperty } from "@firecms/core";
import { SubmissionConfig } from "../../types";

export const formRestSubmitsCollection = buildCollection({
	id: "form_rest_submits",
	path: "form_rest_submits",
	name: "Form REST Submits",
	singularName: "Form REST Submit",
	group: "Forms",
	description: "Form Submission REST Request(front end won't have access to this)",
	properties: {
		submission_config:
			buildProperty<SubmissionConfig>({
				dataType: "map",
				name: "Submission Configuration",
				properties: {
					title: buildProperty({
						dataType: 'string',
						name: 'Title',
						validation: {required: true}
					}),
					url:
						buildProperty({
							dataType: "string",
							name: "Submission URL",
							validation: {
								required: true,
								requiredMessage:
									"Submission URL is required and must be a valid URL format."
							}
						}),
					verb: buildProperty({
						dataType: "string",
						name: "Verb",
						enumValues: {
							GET: "GET",
							POST: "POST",
							PUT: "PUT",
							DELETE: "DELETE"
						}
					}),
					apiKeys:
						buildProperty({
							dataType: "array",
							name: "API Keys",
							of: {
								dataType: "map",
								properties: {
									property: {
										name: "Property",
										validation: {required: true},
										dataType: "string"
									},
									value: {
										name: "Value",
										validation: {required: true},
										dataType: "string"
									}
								} 
							}

						}),
						propertyMapper:
						buildProperty({
							dataType: "array",
							name: "Property Mapper",
							of: {
								dataType: "map",
								properties: {
									formDataProperty: {
										name: "Form Data Property",
										validation: {required: true},
										dataType: "string"
									},
									endpointData: {
										name: "End Point Data",
										validation: {required: true},
										dataType: "string"
									}
								} 
							}

						})
				}
			}),
	}
})